import { videoObserver } from "./_scrollEvents.js";

export function createElement(parameters) {
	if (parameters.node == null) {
		parameters.node = 'div'
	}

	let element = document.createElement(parameters.node);

	if (parameters.id) {
		element.id = parameters.id;
	}
	if (parameters.class) {
		element.className = parameters.class;
	}
	if (parameters.src) {
		element.src = parameters.src;
	}
	if (parameters.href) {
		element.href = parameters.href;
	}

	return element;
}

export function createTextblock(title, titleSize, list, text, button) {
	let container = document.createDocumentFragment();

	let header = createHeader(titleSize, title, 'title')

	if (list.length > 0) {
		list = createList(list);
	} else {
		list = '';
	}

	let textfield = document.createElement('div')
	textfield.className = 'text';
	textfield.append(createParagraph(text))

	// textfield.innerHTML = textfield.innerHTML.replace(/<\/?strong>/g, "");

	if (button) {
		let btn = createButton(button);
		textfield.append(btn)
		container.append(header, list, textfield)
	}
	else {
		container.append(header, list, textfield);
	}

	return container;
}

export function createParagraph(content, classValue = '') {
	if (!content) return;

	let element = document.createElement('template');
	if (classValue) {
		element.className = classValue
	}
	element.innerHTML = content;

	return element.content;
}

export function createButton(button) {
	let wrapper, btn;
	const link = button.link
	const isExternal = !link.includes(window.location.hostname);

	if (button.wrap == true) {
		wrapper = document.createElement('a')
		btn = document.createElement('div');
		wrapper.append(btn)
		btn.dataset.animation = 'viewport'
		wrapper.href = button.link;
		wrapper.setAttribute('data-transition', button.transition);

		if (isExternal) {
			wrapper.setAttribute('target', '_blank');
		}
	} else {
		btn = document.createElement('a');
		btn.href = button.link;
		btn.setAttribute('data-transition', button.transition);

		if (isExternal) {
			btn.setAttribute('target', '_blank');
		}
	}

	btn.classList.add('button');

	let btnContainer = document.createElement('div');
	let btnMask = document.createElement('span')
	btnMask.className = "button__text";

	let firstText = document.createElement('span')
	firstText.className = "button__text--active";
	firstText.innerText = button.text;

	let secondText = document.createElement('span')
	secondText.className = "button__text--hover";
	secondText.dataset.nosnippet = '';

	// secondText.style.visibility = 'hidden';

	secondText.innerText = button.text;

	btnMask.append(firstText, secondText)
	btnContainer.append(btnMask)
	btn.append(btnContainer)

	if (button.wrap == true) {
		return wrapper;
	} else {
		return btn;
	}
}

export function createList(content) {
	var list = document.createElement('ul');
	list.classList.add('list');

	var listItem = content.replaceAll('<br />', '\r\n').split('\r\n').filter(value => Object.keys(value).length !== 0);
	listItem.forEach(i => {
		var item = document.createElement('li')
		item.classList.add('text-mask')
		var text = document.createElement('span')
		text.innerText = i;
		item.append(text)
		list.append(item)
	})

	return list;
}

export function createMail(link) {
	let email = document.createElement('a');
	email.className = "mail text-mask";
	email.href = 'mailto:' + link;
	email.setAttribute('target', '_blank')

	let string = link.split('@');

	email.innerHTML = '<span>' + string[0] + '</span>' + '<span class="icon" data-icon="at"></span>' + '<span>' + string[1] + '</span>';
	return email;
}

export function createGraphics(slug, selection) {
	let container = document.createDocumentFragment();

	var illustrations = document.createElement('div');
	illustrations.className = "illustrations";

	var shapes = document.createElement('div');
	shapes.className = "shapes";

	let risoFormen = [];
	let illustrationen = [];

	switch (slug) {
		case "startseite":
			risoFormen = ["green", "blue", "blue", "green"];
			illustrationen = ["giesskanne", "pflanze"];
			break;
		case "leistungen":
			risoFormen = ["blue", "orange"];
			illustrationen = ["leuchtturm"];
			break;
		case "projekte":
			risoFormen = ["orange", "green"];
			illustrationen = ["truhe"];
			break;
		case "archiv":
			risoFormen = ["blue", "orange"];
			illustrationen = ["truhe_archiv"];
			break;
		case "einblicke":
			risoFormen = ["green", "blue"];
			illustrationen = ["vogel"];
			break;
		case "agentur":
			risoFormen = ["orange", "green", "green", "orange"];
			illustrationen = ["saxophon", "trommel"];
			break;
		case "jobs":
			risoFormen = ["orange", "green"];
			illustrationen = ["avocado"];
			break;
		case "error":
			illustrationen = ["error_1", "error_2", "error_3"];
			break;
	}
	if (selection == 'riso' || selection == 'both') {
		// if ((selection == 'riso' || selection == 'both') && risoFormen.length > 0) {
		risoFormen.forEach((value, index) => {
			let counter = index + 1;
			var riso = document.createElement('div');
			riso.className = 'riso ' + 'riso--' + value;
			var risoMask = document.createElement('div');
			risoMask.className = 'riso-mask form' + counter;
			risoMask.append(riso);
			shapes.append(risoMask);
		})
		container.append(shapes)
	}

	if (selection == 'illustrations' || selection == 'both') {
		// if ((selection == 'illustrations' || selection == 'both') && illustrationen.length > 0) {
		illustrationen.forEach((value, index) => {
			var illu = document.createElement('img');
			illu.className = 'illustration ' + value;
			illu.alt = value;
			illu.src = myVar.templateDir + '/img/illu_' + value + '.svg';
			illustrations.append(illu);
		})
		container.append(illustrations)
	}
	return container;
}

export function createHeader(size, content, classValue = '') {
	let element = document.createElement(size);
	element.className = 'text-mask';
	if (classValue) {
		element.classList.add(classValue);
	}

	let lines = content.split('<br />');

	for (let line of lines) {
		let textMask = document.createElement('span');
		textMask.className = 'mask';
		textMask.innerHTML = '<span>' + line + '</span>';
		element.append(textMask);
	};

	return element;
}

export function createTextLines(string) {
	let fragment = new DocumentFragment();
	const lines = string.split('<br />');

	for (let line of lines) {
		let lineMask = document.createElement('span');
		lineMask.className = 'mask';
		lineMask.innerHTML = '<span>' + line + '</span>';

		fragment.append(lineMask);
	};

	return fragment;
}

// vpPercentage is the size the image takes in the layout
// vpPercentageMobile  is the size the image takes in the mobile layout
export function addSourceSet(image, imageObject, vpPercentage, vpPercentageMobile, useCropped = false, lazyload = false) {
	if (!imageObject) {
		return false;
	}

	let srcSet = imageObject.sizes;

	image.alt = imageObject.alt;
	image.title = imageObject.title;

	if (lazyload === true) {
		image.setAttribute('loading', 'lazy');
	}

	if (useCropped == true) {
		image.setAttribute('srcset',
			srcSet["small_thumbnail_crop"] + ' ' + srcSet["small_thumbnail_crop-width"] + 'w, ' +
			srcSet["medium_thumbnail_crop"] + ' ' + srcSet["medium_thumbnail_crop-width"] + 'w, ' +
			srcSet["large_thumbnail_crop"] + ' ' + srcSet["large_thumbnail_crop-width"] + 'w'
		);
	} else {
		image.setAttribute('srcset',
			srcSet["large"] + ' ' + srcSet["large-width"] + 'w, ' +
			srcSet["medium_large"] + ' ' + srcSet["medium_large-width"] + 'w, ' +
			srcSet["medium"] + ' ' + srcSet["medium-width"] + 'w, ' +
			srcSet["small_medium"] + ' ' + srcSet["small_medium-width"] + 'w, ' +
			srcSet["small"] + ' ' + srcSet["small-width"] + 'w, ' +
			srcSet["tiny"] + ' ' + srcSet["tiny-width"] + 'w'
		);
	}

	image.setAttribute('sizes',
		"(max-width: 420px) " + vpPercentageMobile + "vw, " +
		vpPercentage + "vw");

	// Fallback for older browsers
	// TODO: which size should we use?
	image.setAttribute('src', srcSet.medium);
}

export function createVideo(object) {
	let video = document.createElement('video');
	video.setAttribute('muted', '');
	video.setAttribute('autoplay', '');
	video.setAttribute('playsinline', '');
	video.muted = true;
	video.loop = true;

	let source = document.createElement('source')

	if (object.url) {
		source.src = object.url + "#t=0.001";
	} else if (object.videofile) {
		source.src = object.videofile + "#t=0.001";
	} else {
		return false;
	}
	source.setAttribute("type", "video/mp4")
	videoObserver.observe(video);
	video.append(source)

	return video;
}

export function createHeaderDuplicate() {
	let cloneWrapper = document.createElement('div')
	cloneWrapper.className = 'clone-wrapper';

	let headerClone = document.createElement('div')
	headerClone.className = 'header-clone';

	let logo = document.querySelector('header > .logo');
	let buttonBowl = document.querySelector('header .button--modal');
	let buttonMenu = document.querySelector('header .button--menu');

	let footer = document.querySelector('.footer-desktop');
	let footerClone = footer.cloneNode(true);
	footerClone.className = 'footer-clone';

	headerClone.append(logo.cloneNode(true), buttonBowl.cloneNode(true), buttonMenu.cloneNode(true))
	cloneWrapper.append(headerClone, footerClone)

	return cloneWrapper;
}

export function addBrowserBorder(container) {
	container.classList.add('browser-border');
	let browserDecoration = document.createElement('template')
	for (let i = 0; i < 3; i++) {
		let el = document.createElement('div');
		el.className = "deko";
		container.append(el)
	}
}

export function emptyElement(el) {
	while (el.firstChild) {
		el.removeChild(el.firstChild);
	}
}